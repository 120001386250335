.agora-container-video {
    position: relative;
    width: 100%;
    height: 100%;
}

.agora-other-person-video {
    width: 20%;
    height: 20%;
    position: absolute;
    z-index: 1;
}

.agora-me-video {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    
}